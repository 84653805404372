<template>
	<div
		class="relative"
		:class="isHeroForm ? 'flex-none max-w-2xl space-y-2 md:space-y-0 md:space-x-3 md:flex' : 'flex flex-col w-full max-w-sm px-8 mx-auto md:max-w-lg'"
	>
		<h2
			v-if="!isHeroForm"
			class="pb-16 mx-auto text-3xl font-semibold text-center"
		>
			{{ $t('address-checker.h2') }}
		</h2>
		<form
			:id="id"
			class="relative"
			:class="isHeroForm ? 'md:w-3/4' : 'flex flex-col pb-10'"
			@submit.prevent="submit"
		>
			<FormTextInput
				id="address-checker-search-input"
				v-model="searchTerm"
				:input-type="SEARCH"
				autocomplete="off"
				:placeholder="isHeroForm ? $t('address-checker.hero-placeholder') : $t('address-checker.placeholder')"
				:input-class="searchFocusClasses"
				border-class="border-2 border-gray-300 focus:border-white"
				:show-error-message-on-invalid="showErrorMessageOnInvalid"
				:label-class="!isHeroForm ? 'absolute -top-3' : ''"
				:show-icon="showIcon"
				:floating-label="floatingLabel"
				:label="$t('address-checker.placeholder')"
				:hide-label="hideLabel"
				:error-message="errorMessage"
				:show-required-astrisk="false"
				error-message-always-enabled
				error-message-position="top-24 md:top-auto"
				error-message-classes="pl-1 text-xs italic text-red-500 md:text-nowrap"
				state="error"
				required
				@blur="searchBlurred"
				@focus="searchFocused"
				@keyup="handleKeyPress"
			>
				<template
					v-if="showIcon"
					#icon
				>
					<IconPin class="text-mx-orange" />
				</template>
			</FormTextInput>
			<Autocompleter
				v-if="resultList.length && showList"
				class="absolute z-10 w-full"
				:class="isHeroForm ? '-mt-[8px]' : 'top-11'"
				:result-list="resultList"
				:is-search-active="isSearchActive"
				:active-search-item="activeSearchItem"
				@set-search-term="handleSetSearchTerm"
			/>
		</form>
		<FormButton
			:form="id"
			class="w-full px-4 text-sm font-bold uppercase shrink-0"
			:focus-classes="submitfocusClasses"
			:button-width="isHeroForm ? 'md:w-48' : 'w-full'"
			submit
			:disabled="false"
			:is-loading="loading"
			:button-text="buttonText"
		/>
	</div>
</template>

<script setup lang="ts">
import { toRefs, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrackEvent } from '@/composables/useEventTracking'
import { SEARCH } from '@/constants/form/text-inputs/types.js'
import { useAddressAutocompleter } from '@/composables/useAutocompleter'
import { useToast } from '@/composables/useToast'
import {
	ERROR_TRACK,
	ADDRESS_CHECK_AUTOCOMPLETE_SELECT,
	ADDRESS_CHECK_SUBMIT
} from '@/constants/gtm/events.js'
import type {
	AddressCheckAutocompleteSelectEvent,
	ErrorEvent,
	AddressCheckSubmitEvent
} from '@/types/gtm/events'
import { useProspectAddress } from '@/composables/useProspectAddress'

const { loading, getProspectAddressData } = useProspectAddress()
const { addToast } = useToast()
const { t } = useI18n({
	useScope: 'global'
})

const props = defineProps({
	buttonText: {
		type: String,
		default: 'Check Availability'
	},
	isHeroForm: {
		type: Boolean,
		default: false
	},
	id: {
		type: String,
		required: true
	}
})

const { buttonText, isHeroForm, id } = toRefs(props)

const { searchTerm, activeSearchItem, resultList, isSearchActive, setSearchTerm, searchFocused, searchBlurred, handleKeyPress } = useAddressAutocompleter()


const showErrorMessageOnInvalid = ref(false)
const errorMessage = ref('')
const showList = ref(false)

watch(searchTerm, () => {
	showList.value = true
})

const handleSetSearchTerm = (searchTerm: string, index: number) => {
	const trackedEvent: AddressCheckAutocompleteSelectEvent = {
		event: ADDRESS_CHECK_AUTOCOMPLETE_SELECT,
		action: 'click',
		category: 'Buttons',
		label: 'Address Checker Autocomplete Select',
		address: searchTerm,
		form_id: id.value
	}
	useTrackEvent(trackedEvent)
	setSearchTerm(searchTerm, index)
}

const submit = async () => {
	// const addressSelectedFromAutocompleter = activeSearchItem.value < 0
	if (!searchTerm.value || !searchTerm.value?.length /* || !addressSelectedFromAutocompleter */) {
		addToast({
			title: t('address-checker.error-toast.title'),
			message: t('address-checker.error-toast.body')
		})
		const trackedEvent: ErrorEvent = {
			event: ERROR_TRACK,
			action: 'submit',
			category: 'Buttons',
			label: 'Address Checker Error',
			message: 'Address Checker: No address entered',
			source: id.value
		}
		useTrackEvent(trackedEvent)
	} else {
		const trackedEvent: AddressCheckSubmitEvent = {
			event: ADDRESS_CHECK_SUBMIT,
			action: 'submit',
			category: 'Buttons',
			label: 'Address Checker Submit',
			address: searchTerm.value,
			form_id: id.value
		}
		useTrackEvent(trackedEvent)
		const res = await getProspectAddressData(searchTerm?.value)
		if(res instanceof Error) {
			showErrorMessageOnInvalid.value = true
			errorMessage.value = searchTerm.value.includes(',') ? t('address-checker.error-toast.body') : t('address-checker.address-not-found-error-message')
		}
	}
	showList.value = false
}

const showIcon = computed(() => {
	return isHeroForm.value
})
const floatingLabel = computed(() => {
	return !isHeroForm.value
})
const hideLabel = computed(() => {
	return isHeroForm.value
})
const submitfocusClasses = computed(() => {
	return isHeroForm.value
		? 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-mx-navy focus:ring-mx-orange focus:ring-opacity-80'
		: 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-mx-current focus:ring-mx-orange focus:ring-opacity-80'
})
const searchFocusClasses = computed(() => {
	return isHeroForm.value
		? 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mx-navy focus:ring-mx-orange focus:ring-opacity-80'
		: ''
})
</script>
